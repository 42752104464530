import React from 'react';
import './App.css';
import logo from './assets/bio_logo.png'; // Assuming the logo is placed here

function App() {
  return (
    <div className="App">
      {/* Title Section */}
      <section className="title-section">
        <img src={logo} alt="Biology Teaching Center Logo" className="logo" />
        <h1>Biology Teaching Center by Dr. C. P. Mishra</h1>
      </section>

      {/* Body Section - Two Rows Layout */}
      <div className="content-container">
        
        {/* Row 1: Description & Why Choose Us */}
        <div className="row center">
          <div className="card">
            <h2>Description:</h2>
            <p>
              Welcome to the best Biology coaching classes in Ara city! We offer expert coaching for students preparing for 10+2 and Graduation in Biology. Our classes are designed to provide comprehensive knowledge, hands-on practice, and personalized attention to ensure the best results.
            </p>
            <p>
              Dr. C. P. Mishra, a seasoned educator with years of experience, uses innovative methods to make Biology easy and interesting for all students. Our goal is to not only help you understand the concepts but also to ignite your passion for Biology.
            </p>
            <p>
              At our center, you will get the right mix of theoretical knowledge and practical skills, making you well-prepared for exams. We believe in interactive learning, and our classes are designed to help you excel and achieve top marks.
            </p>
            <p>
              We focus on individual learning styles, ensuring each student understands the material at their own pace. Whether you're aiming for a top score in your school exams or preparing for university entrance exams, our coaching will set you on the path to success.
            </p>
            <p>
              So why wait? Join us today and experience the best coaching in Ara! Bring your friends along, and together we can achieve academic success.
            </p>
          </div>

          <div className="card">
            <h2>Why Choose Us?</h2>
            <ul>
              <li>More than two decades of experience in teaching</li>
              <li>Experienced and qualified teacher – Dr. C. P. Mishra</li>
              <li>Comprehensive study material</li>
              <li>Interactive learning environment with practical sessions</li>
              <li>Regular tests and assessments to track progress</li>
              <li>Personalized attention and doubt-clearing sessions</li>
              <li>Affordable fees with flexible schedules</li>
            </ul>
          </div>
        </div>

        {/* Row 2: Address & Contact Information */}
        <div className="row center">
          <div className="card">
            <h2>Address:</h2>
            <p>In front of Sudha Dairy, Katira Road, Ara, District - Bhojpur, State - Bihar</p>
          </div>

          <div className="card">
            <h2>Contact Information:</h2>
            <p><strong>Call us: +91-9835215792</strong></p>
            <p><strong>Mail us at:</strong> chakrapanimishra26@gmail.com</p>
            <p className="subtitle">For any query reach us on:</p>
            <p><strong>+91-9835215792</strong></p>
          </div>
        </div>

      </div>

      {/* Footer Section */}
      <footer className="App-footer">
        <p>&copy; 2024 Biology Teaching Center, Katira Ara</p>
      </footer>
    </div>
  );
}

export default App;
